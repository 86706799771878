import { FC } from 'react';
import Link from 'next/link';
import { getLanguage, getAuthorFullName } from '@/helpers/helpers';
import { IBook, IAuthor, IGenre } from '@/interfaces/home.interface';
import { IPodcastAuthor } from '@/interfaces/podcastsPage.interface';
import { parseISO, format } from 'date-fns';
import DraftSticker from '../draft-sticker/DraftSticker';
import VersionSticker from '../version-sticker/VersionSticker';
import { getTruncatedTxt } from '@/helpers/helpers';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import { hideImgOnError } from '@/helpers/helpers';
import styles from './BookCardItem.module.scss';

interface IBookCardItem {
  book: IBook | any;
  author: IAuthor | any;
  rating: number;
  alias: string;
  authorAlias?: string;
  createdAt?: string;
  draft?: boolean;
  readOnline?: boolean;
  isPodcast?: boolean;
  isAuthorsPage?: boolean;
}

const BookCardItem: FC<IBookCardItem> = ({ alias, book, author, rating, authorAlias, createdAt, draft, readOnline, isPodcast, isAuthorsPage }) => {
  const { title, type, year, lang, description, genres } = book;

  const { first_name, middle_name, last_name } = author;
  const authorFullName = getAuthorFullName(first_name, middle_name, last_name);

  const getAuthorsAlias = () => (!!authorAlias ? authorAlias : author.alias);

  const fullAuthorAlias = `/avtor/${getAuthorsAlias()}/`;
  const fullBookAlias = `/avtor/${getAuthorsAlias()}/${alias}/`;
  const fullBookReadAlias = `${fullBookAlias}read/`;
  const podcastAlias = `/podcasts/list/${alias}/`;

  //Stars render
  const getBookCardRatingStars = (rating: number | undefined) => {
    const bookRating = Number(rating);

    if (bookRating > 0 && bookRating < 6) {
      const greyStars = 5 - bookRating;
      return (
        <>
          {Array.from(Array(bookRating), () => {
            return <span className={`${styles.bookCard__star} ${styles.bookCard__star_active}`} key={`${Math.random()}-orange`}></span>;
          })}
          {Array.from(Array(greyStars), () => {
            return <span className={`${styles.bookCard__star}`} key={`${Math.random()}-gray`}></span>;
          })}
          {bookRating > 0 && <span className={styles.bookCard__starRate}>{bookRating}</span>}
        </>
      );
    } else {
      return Array.from(Array(5), () => {
        return <span className={`${styles.bookCard__star}`} key={`${Math.random()}-gray`}></span>;
      });
    }
  };

  return (
    <div className={styles.bookCard}>
      <div className={styles.bookCard__parts}>
        <div className={styles.bookCard__left}>
          <div className={type === 'audio_book' || isPodcast ? `${styles.bookCard__imgWrap} ${styles.bookCard__imgWrap_audio}` : `${styles.bookCard__imgWrap}`}>
            <Link prefetch={false} href={isPodcast ? podcastAlias : fullBookAlias} title={`Скачать ${title}`}>
              <img loading="lazy" width="185" height="273" src={`${DEV_ABSOLUTE_URL}/covers_200/${book.external_id}.jpg`} alt={title} className={styles.bookCard__img} onError={hideImgOnError} />
              <span className={styles.bookCard__type}>{!!isPodcast ? 'Подкаст' : `${type === 'audio_book' ? 'Aудио' : 'Текст'}`}</span>
              {draft && <DraftSticker />}
            </Link>
            {!!book.allow_full_free && book.allow_full_free > 0 && book.type !== 'pdf' && <VersionSticker />}
          </div>

          <div className={styles.bookCard__rating}>{getBookCardRatingStars(rating)}</div>

          <div className={styles.bookCard__btn}>
            {type === 'book' && readOnline && (
              <Link prefetch={false} href={fullBookReadAlias} className={styles.bookCard__readOnlineBtn}>
                Читать онлайн
              </Link>
            )}
          </div>
        </div>

        <div className={styles.bookCard__right}>
          <div className={styles.bookCard__info}>
            <div className={styles.bookCard__dateWrap}>
              {!!createdAt && (
                <div className={styles.bookCard__date}>
                  Добавлено{' '}
                  <span>
                    <time dateTime={createdAt}>{format(parseISO(createdAt), 'dd.MM.yyyy HH:mm')}</time>
                  </span>
                </div>
              )}
            </div>
            <div className={styles.bookCard__title}>
              <Link prefetch={false} href={isPodcast ? podcastAlias : fullBookAlias}>
                {title}
              </Link>
            </div>
            <div className={`${styles.bookCard__author} ${styles.bookCard__author_authors}`}>
              {isPodcast ? (
                book.authors.map((author: IPodcastAuthor) => (
                  <span className={styles.bookCard__genres} key={author.alias}>
                    <span>{getAuthorFullName(author.first_name, author.middle_name, author.last_name)}</span>
                  </span>
                ))
              ) : (
                <div className={styles.bookCard__author}>
                  {isAuthorsPage ? (
                    <span>{authorFullName}</span>
                  ) : (
                    <Link prefetch={false} href={fullAuthorAlias}>
                      {authorFullName}
                    </Link>
                  )}
                </div>
              )}
            </div>
            {year && (
              <div className={styles.bookCard__year}>
                Год выхода: <span>{year}</span>
              </div>
            )}
            <div className={styles.bookCard__lang}>
              Язык: <span>{getLanguage(lang)}</span>
            </div>
          </div>

          <div className={styles.bookCard__desc}>
            <div className={styles.bookCard__annotation}>{!!description && getTruncatedTxt(description, 100)}</div>
            <div className={styles.bookCard__genres}>
              {genres !== undefined &&
                genres.map((genre: IGenre) => (
                  <span key={genre.id}>
                    {' '}
                    <Link prefetch={false} href={`/zhanr/${genre.alias}/`}>
                      {genre.title}
                    </Link>
                  </span>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.bookCard__desc} ${styles.bookCard__desc_mob}`}>
        <div className={styles.bookCard__annotation}>{!!description && getTruncatedTxt(description, 100)}</div>
        <div className={styles.bookCard__genres}>
          {genres !== undefined &&
            genres.map((genre: IGenre) => (
              <span key={genre.id}>
                {' '}
                <Link prefetch={false} href={`/zhanr/${genre.alias}/`}>
                  {genre.title}
                </Link>
              </span>
            ))}
        </div>
      </div>

      <div className={`${styles.bookCard__rating} ${styles.bookCard__rating_tiles}`}>{getBookCardRatingStars(rating)}</div>

      {type === 'book' && readOnline && (
        <div className={`${styles.bookCard__btn} ${styles.bookCard__btn_mob}`}>
          <Link prefetch={false} href={fullBookReadAlias} className={styles.bookCard__readOnlineBtn}>
            Читать онлайн
          </Link>
        </div>
      )}

      {type === 'audio_book' && !isPodcast && (
        <div className={styles.bookCard__download}>
          <span>Скачать бесплатно в формате mp3!</span>
        </div>
      )}
    </div>
  );
};

export default BookCardItem;
